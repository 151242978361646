import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
// 公共路由
export const constantRoutes = [
  {
    path: '/',
    name: 'evaluationScreen',
    component: () => import('@/views/evaluationScreen'),
  },
  {
    path: '/user',
    name: 'userDataScreen',
    component: () => import('@/views/userDataScreen'),
  },
  {
    path: '/join',
    name: 'joinScreen',
    component: () => import('@/views/joinScreen'),
  },
]

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})
